@import url("./lib/fonts/fonts.css");
@import url("./lib/icons.less");
@import url("./lib/settings.less");
@import url("./lib/functions.less");
@import url("./lib/animations.less");
@import url("./classes/classes.less");


.text-container{
    display:block;
    margin:0;
    width:100% ;
    height:auto;
    padding: 0 ;
    position:absolute;
    bottom:24%;
    
    p,h3{
        position:relative;
        z-index:15;
    }
    
    h3{
        margin:0;
        display:block;
        width:100%;
        color:@c_5th;
        text-align:center;
        font-size:( @f_mainSize * 3 );
        
        .f_1stFontSemiBold;
    }
    p{
        text-align:center;
        color:@c_5th;
        font-size: ( @f_mainSize * 1.5 );
        
        .f_1stFont;
    }
}

.text-container-2{
    display:block;
    margin:0;
    width:100% ;
    height:auto;
    padding: 0 ;
    position:absolute;
    bottom: ( @p_main * 3 );

    p,h3{
        position:relative;
        z-index:15;
    }

    h3{
        margin:0;
        margin-bottom:@p_main;
        margin-left:( @p_main * 2 );
        display:block;
        width:100%;
        color:@c_5th;
        text-align:left;
        font-size:( @f_mainSize * 2.8 );

        .f_1stFontSemiBold;
    }
    p{
        text-align:left;
        margin: 0 @p_main ( @p_main / 4 ) ( @p_main * 2 );
        color:@c_5th;
        font-size: ( @f_mainSize * 1.5 );

        .f_1stFont;
    }
}



// styling cloase-btn
#logistics, #research, #practical, #marketing, #manufacturing{

    span.close-btn{

        display:block;
        width:70px!important;
        height:70px!important;
        margin:0;
        padding:0;
        padding-bottom:5px;
        position:absolute;
        top:0;
        right: ( @p_main * 2 );
        background-color:@c_6th!important;
        z-index:10;
        border-radius: 0 0 50% 50%;

        &:before{

            content:" ";
            display:block;
            width:55px;
            height:55px;
            background-image: url("/assets/img/svg/icon_close_zeichen.svg");
            margin: 15px 7.5px 7.5px 7.5px ;
            padding:0;

        }
    }
}

#logistics{
        
    display:block;
    width:100%;
    height:100%;
    
    #logistics-content{

        display:block;
        width:100%;
        height:100%;
        
        ul{

            list-style:none;
            margin:0;
            padding:0;
            width:100%;
            height:100%;

            .displayFlex(row);
            .justifyContent(space-between);
            .alignContent(flex-start);
            .alignItems(flex-start);
            .flexWrap(nowrap);
            
            li{
                display:block;
                margin:0;
                padding:0;
                width:24%;
                height:0;
                position:relative;

                .standardAnimation(all);

                .img-box{
                    background-position:center;
                    background-size:cover;

                    .filter(@_property: grayscale, @_value: 1);
                }

                &.fade-1{
                    p, h3{
                        color:@c_6th;
                    }
                    .img-box{
                        background-image:url('/assets/img/factory/bg_img_factory_logistics_zoll.png');
                    }
                }
                &.fade-2{
                    p, h3{
                       // color:@c_6th;
                    }
                    .img-box{
                        background-image:url('/assets/img/factory/bg_img_factory_logistics_airfreight.png');

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:60%;
                            position:absolute;
                            bottom:0;

                            .gradient5();
                        }
                    }
                }

                &.fade-3{
                    p, h3{
                        color:@c_6th;
                    }
                    .img-box{
                        background-image:url('/assets/img/factory/bg_img_factory_logistics_seafreight.png');


                    }
                }


                &.fade-4{
                    p, h3{
                        // color:@c_6th;
                    }
                    .img-box{
                        background-image:url('/assets/img/factory/bg_img_factory_logistics_lkw.png');

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:60%;
                            position:absolute;
                            bottom:0;

                            .gradient5();
                        }
                    }
                }
                .text-container{
                    h3{
                        @media screen and ( max-width: @q_1st ){
                            font-size: ( @f_mainSize * 2 );
                            line-height:120%;
                        }
                    }
                    @media screen and ( max-width: @q_1st ){
                       bottom:25%;
                    }
                }
            }
        }
        #text-box-logistics{
            position:absolute;
            display:block;
            height:auto;
            bottom:-50%;
            width:80%;
            padding: @p_main;
            background-color:@c_6th;
            left:50%;
            
            .shadow3;
            .transform2d(@translateX: -50%);
            
            p{
                font-size:( @f_mainSize * 2 );
                color:@c_5th;
                width:100%;
                height:100%;
                text-align:center;
                margin:0;
                padding:0;

                @media screen and ( max-width: @q_1st ){
                    font-size: ( @f_mainSize * 1.5 );
                }
            }
            @media screen and ( max-width: @q_1st ){
                padding: ( @p_main / 2 );
            }
        }
    }
}

#research{

    display:block;
    height:100%;
    width:100%;
    opacity:0;
    .standardAnimation(all);

    &.fade{

        display:block;
        opacity:1;

        h1{
            margin:0;
            padding:0;
            position:absolute;
            top:-50px;
            left:0;
            color:@c_5th;
            width:100%;
            text-align:left;

        }
        #research-content{
            display:block;
            width:65%;
            height:100%;
            margin:0;
            margin-left:35%;

            ul{

                list-style:none;
                display:inline-block;
                width:100%;
                height:100%;
                padding:0;

                .displayFlex(column);
                .justifyContent(flex-start);
                .flexWrap(nowrap);
                .alignItems(flex-start);
                .alignContent(flex-start);

                li{

                    opacity:0;
                    height:0;

                    h3,p{
                        color:@c_5th!important;
                        padding-right:@p_main;
                    }
                    h3{

                        font-size: ( @f_mainSize * 2.8 );
                        margin: ( @p_main * 2 ) 0 0 0;
                        .f_1stFontSemiBold;

                        @media screen and ( max-width: @q_1st ){
                            font-size: ( @f_mainSize * 2 );
                            margin:  0;
                        }
                    }
                    p{
                        font-size: ( @f_mainSize * 1.25 );
                        //margin-top:0;
                        .f_2ndFont;

                        @media screen and ( max-width: @q_1st ){
                            font-size: ( @f_mainSize * 0.9 );
                            margin:0;
                        }
                    }
                }
                @media screen and ( max-width: @q_1st ){
                    margin-top: ( @p_main * 3 );
                }
            }
            span.bg-white-text{
                display:block;
                width:65%;
                height:0 ;
                background-color:@c_6th;
                opacity: 0.75;
                position:absolute;
                padding:@p_main;
                top:0;
                right:0;
                z-index:-1;
            }
        }

        #research-background{

            display:block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            margin:0;
            padding:0;
            z-index:-2;

            background-image: url('/assets/img/factory/bg_research_development.png');
            background-repeat:no-repeat;
            background-size: cover;

            .standardAnimation(all);
            .transform2d(@scale: 1.1);
        }
    }
}

#practical{
    
    display:block;
    width:100%;
    height:100%;
    
    #practical-content{
        
        display:block;
        width:100%;
        height:100%;
        
        ul{
            
            list-style:none;
            margin:0;
            padding:0;
            width:100%;
            height:100%;
            
            .displayFlex(row);
            .justifyContent(space-between);
            .alignContent(flex-start);
            .alignItems(flex-start);
            .flexWrap(nowrap);
            
            li{
                display:block;
                margin:0;
                padding:0;
                width:49.5%;
                height:0;
                position:relative;
                
                &.fade-1{
                    .box-3D{
                        .text-container{
                            
                            top:0;
                            padding:( @p_main * 2 );
                            padding-top: ( @p_main * 4 );
                            display:block!important;
                            width:auto!important;

                            h3, p{
                                text-align:left;
                            }
                        }
                    }
                }
                
                &.fade-2{
                    //background-color:@c_7th;

                    .img-box{

                        background-image:url('/assets/img/factory/bg_img_factory_practical_application.png');
                        background-position: center center;
                        background-size:cover;
                        background-repeat:no-repeat;


                        .filter(@_property: grayscale, @_value: 1);
                        
                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:60%;
                            position:absolute;
                            bottom:0;

                            .gradient6();
                        }
                    }
                }
                
                .standardAnimation(all);
                
            }
        }
    }
}

#marketing{

    display:block;
    width:100%;
    height:100%;

    h1{

    }
    #marketing-content{

        display:block;
        width:100%;
        height:100%;

        ul{
            list-style:none;
            margin:0;
            padding:0;
            width:100%;
            height:100%;

            .displayFlex(row);
            .justifyContent(space-between);
            .alignContent(flex-start);
            .alignItems(flex-start);
            .flexWrap(nowrap);

            li{

                display:block;
                margin:0;
                padding:0;
                width:32%;
                height:0;
                position:relative;

                .standardAnimation(all);

                div.wrapper-3D{


                    .box-3D{
                        .text-container, .text-container-2{
                            p,h3{
                                color:@c_5th;
                            }
                            p{
                                margin:0;
                                margin-left:@p_main;
                                text-align:left!important;
                            }
                            h3{
                                margin-bottom:( @p_main * 1.5 );

                                @media screen and ( max-width: @q_1st ){
                                    font-size: ( @f_mainSize * 2 )!important;
                                }
                            }
                        }
                    }
                }
                &.fade-1{
                    h3, p{
                        color:@c_6th!important;
                    }
                    .img-box{

                        background-image:url('/assets/img/factory/bg_img_factory_marketing_werbemittel.png');
                        background-size:cover;
                        background-position:center center;
                        background-repeat:no-repeat;

                        .filter(@_property: grayscale, @_value: 1);
                    }
                }
                &.fade-2{
                    h3,p{
                        color:@c_5th!important;
                    }
                    .img-box{

                        background-image:url('/assets/img/factory/bg_img_factory_marketing_customised.png');
                        background-size:cover;
                        background-position:center center;
                        background-repeat:no-repeat;

                        .filter(@_property: grayscale, @_value: 1);
                    }
                }
                &.fade-3{
                    h3{
                        color:@c_5th!important;
                        
                    }
                    .img-box{

                        background-image:url('/assets/img/factory/bg_img_factory_marketing_digital.png');
                        background-size:cover;
                        background-position:center center;
                        background-repeat:no-repeat;

                        .filter(@_property: grayscale, @_value: 1);

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:60%;
                            position:absolute;
                            bottom:0;

                            .gradient5();
                            
                        }
                    }
                }
            }
        }
    }
}

#manufacturing{
    
    display:block;
    width:100%;
    height:100%;
    
    #manufacturing-content{
        
        display:block;
        width:100%;
        height:100%;
    
        ul{
        
            list-style:none;
            margin:0;
            padding:0;
            width:100%;
            height:100%;
        
            .displayFlex(row);
            .justifyContent(space-between);
            .alignContent(flex-start);
            .alignItems(flex-start);
            .flexWrap(nowrap);
        
            li{
                display:block;
                margin:0;
                padding:0;
                width:49.5%;
                height:0;
                position:relative;
                
                &.fade-1{
                    .img-box{

                        background-image:url('/assets/img/factory/bg_img_factory_manufacturing.png');
                        background-size:cover;
                        background-position:center center;
                        background-repeat:no-repeat;

                        .filter(@_property: grayscale, @_value: 1);

                        &:before{
                            content:" ";
                            display:block;
                            width:100%;
                            height:60%;
                            position:absolute;
                            bottom:0;

                        }
                    }
                }
                
                &.fade-2{
                    //background-color:@c_7th;
                    
                    .box-3D{
                        .text-container{

                            top:0;
                            width:auto!important;
                            padding: ( @p_main * 2);
                            padding-top:( @p_main * 4 );

                            h3, p{
                                text-align:left;
                            }
                        }
                    }
                }
            
                .standardAnimation(all);
            
            }
        }
        
    }
}

div.wrapper-3D{
    
    display:block;
    width:100%;
    height:100%;
    perspective: 1800px;
    
    .box-3D{
        
        display:block;
        width:100%;
        height:100%;
        transform-style:preserve-3d;
        transform: rotate3d(0, 1,0,90deg);
        opacity:1;
    
        .standardAnimation(all, @_time:250ms);
    
        &.afterAnimation{
            transform: rotate3d(0, 1,0,0);
        }
        .img-box{
            display:block;
            width:100%;
            height:100%;
            //background-color:@c_7th;
            background-color:@c_7th;
            
        
            .img-box-side{
                display:block;
                width:2px;
                height:100%;
                background-color:@c_5th;
                opacity:1;
                transform-style:preserve-3d;
                transform-origin:0% 50%;
                transform: rotate3d(0,1,0, -91deg)
            }
        }
    }
}